.item {
  @apply relative;
  cursor: pointer;
}

.item-image {
  @apply hidden absolute bottom-4 w-20 h-20 lg:w-32 lg:h-32 left-6 lg:left-0 object-cover;
}

.item:hover .item-image {
  @apply block;
}
