/* .swiper_container {
  height: 25rem;
  padding: 2rem 0;
  text-align: center;
  position: relative;
}

.CatSwiperSlide {
  width: 15rem;
  height: 12rem;
  position: relative;
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }
  .CatSwiperSlide {
    width: 10rem !important;
    height: 12rem !important;
  }
  .swiper-slide .ssimg {
    width: 8rem !important;
    height: 8rem !important;
  }
}

.swiper-slide .ssimg {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
} */

.cswiperr {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cswiper-sliderr {
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
}

.cswiper-sliderr img {
  display: block;
  width: 100%;
  object-fit: cover;
}
