.blankSwiper {
    width: 250px;
    height: 300px;
    padding: 40px;
  }
  
  .blankSwiper-slide {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* border-radius: 18px; */
    /* font-size: 22px; */
    /* font-weight: bold; */
    /* color: #700a0a; */
  }
  .blankSwiper-slide img{
    /* border-radius: 18px; */
    height: 100%;
  }
  