@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap");

.overlay {
  /* background-color: #0000008a; */
  /* background-color: #00000059; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.addGoogleFond {
  font-family: "Roboto", sans-serif;
}

.swiperr {
  width: 100%;
  height: 100%;
}

.swiper-slider {
  text-align: center;
  font-size: 8px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slider img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.communitySwiper {
  width: 90%;
  /* height: 100%; */
  background-color: #faf5ff;
  padding-bottom: 30px;
}

.communitySwiper-slide {
  background-position: center;
  background-size: cover;
  background: #fff;
  width: 100%;
  height: 400px;
  align-items: center;
}

.communitySwiper-slide .log {
  height: 108px;
}

.communitySwiper-slide .img {
  width: 100%;
  height: 252px;
}

.communitySwiper-mobile {
  width: 100%;
  background-color: #faf5ff;
  padding: 0 30px 30px 30px;
}

.communitySwiper-slide-mobile {
  background-position: center;
  background-size: cover;
  background: #fff;
  width: 100%;
  align-items: center;
}

.communitySwiper-slide-mobile .img {
  width: 100%;
  height: 252px;
}

.communitySwiper-slide-mobile .log {
  /* width: 100%; */
  height: 108px;
}
