@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&family=Kosugi+Maru&family=Libre+Baskerville:wght@700&family=M+PLUS+Rounded+1c:wght@300;400;500;700&family=Zen+Maru+Gothic:wght@300;400;500;700&display=swap");

/* google font 1 */
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&family=Libre+Baskerville:wght@700&display=swap");

/* google font 2 */
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&family=Libre+Baskerville:wght@700&family=Zen+Maru+Gothic:wght@300;400;500;700&display=swap");

/* google font 3 */
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&family=Kosugi+Maru&family=Libre+Baskerville:wght@700&family=Zen+Maru+Gothic:wght@300;400;500;700&display=swap");

/* google font 4 poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

@layer base {
  body {
    margin: 0;
    font-family: "Kiwi Maru", serif;
    /* font-family: "Zen Maru Gothic", serif; */
    /* font-family: "Kosugi Maru", sans-serif; */
    /* font-family: 'M PLUS Rounded 1c', sans-serif; */
    font-weight: 400;

    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  .active {
    background-color: #9a583b;
    border: 2px solid #9a583b;
    border-radius: 5px;
    color: white;
  }
  /* .overflow-hidden{
    overflow: hidden;
  } */
}
