.demo-wrap {
  overflow: hidden;
  position: relative;
}

.demo-bg {
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.demo-content {
  position: relative;
}
