.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-bg {
  font-family: "Poppins", sans-serif;
  /* background: linear-gradient(to bottom right, #733e24 50%, #513529 45%); */
  background: #f5ebe5;
  transition: background 1s ease;
  /* pointer-events: none; */
}
.curved-div {
  width: 200px;
  height: 200px;
  background-color: #3498db; /* Your background color */
  border-radius: 20px; /* Adjust the value to control the curvature */
}
