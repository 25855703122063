.vipswiper {
  /* width: 240px;
    height: 320px; */
  width: 280px;
  height: 190px;
  padding: 20px;
}

.vipswiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 18px; */
  /* font-size: 22px; */
  font-weight: bold;
  /* color: #fff; */
  /* border-radius: 18px; */
}
.vipswiper-slide img {
}

/* .vipswiper-slide:nth-child(1n) {
  background-color: rgb(206, 17, 17);
}

.vipswiper-slide:nth-child(2n) {
  background-color: rgb(0, 140, 255);
}

.vipswiper-slide:nth-child(3n) {
  background-color: rgb(10, 184, 111);
}

.vipswiper-slide:nth-child(4n) {
  background-color: rgb(211, 122, 7);
}

.vipswiper-slide:nth-child(5n) {
  background-color: rgb(118, 163, 12);
}

.vipswiper-slide:nth-child(6n) {
  background-color: rgb(180, 10, 47);
}

.swiper-slide:nth-child(7n) {
  background-color: rgb(35, 99, 19);
}

.swiper-slide:nth-child(8n) {
  background-color: rgb(0, 68, 255);
}

.swiper-slide:nth-child(9n) {
  background-color: rgb(218, 12, 218);
}

.swiper-slide:nth-child(10n) {
  background-color: rgb(54, 94, 77);
} */
