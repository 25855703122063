.gswiper {
    width: 100%;
    height: 60%;
    padding: 30px 0;
  }
  
  .gswiper-slider {
    /* text-align: center; */
    /* font-size: 8px; */
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .gswiper-slider img {
    /* display: block;
    width: 100%;
    height: 50%;
    object-fit: cover; */
  }