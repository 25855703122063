@import url("https://fonts.googleapis.com/css2?family=Kaisei+Decol:wght@500&family=Kiwi+Maru:wght@300;400;500&family=Kosugi+Maru&family=Libre+Baskerville:wght@700&family=M+PLUS+Rounded+1c:wght@300;400;500;700&family=Zen+Maru+Gothic:wght@300;400;500;700&display=swap");

.font-fmly {
  font-family: "kaisei-decol", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.rswiper {
  width: 80%;
  /* padding-top: 10px; */
  /* padding-bottom: 50px; */
}
.rmswiper {
  width: 100%;
  /* padding-top: 10px; */
  /* padding-bottom: 50px; */
}
.disswiper {
  width: 60%;
  /* padding-top: 10px; */
  /* padding-bottom: 50px; */
}
.dismswiper {
  width: 80%;
  /* padding-top: 10px; */
  /* padding-bottom: 50px; */
}

/* .rswiper-slide {
  background-position: center;
  background-size: cover;
  width: 250px;
  height: 250px;
} */

.trendswiper {
  width: 100%;
}
.trendswiper-slide {
  background-position: center;
  background-size: cover;
  background: #fff;
  width: 306px;
  align-items: center;
}
.trendswiper-slide .log {
  height: 108px;
}
.trendswiper-slide .img {
  width: 100%;
  height: 252px;
}

.trendswipermobile {
  width: 100%;
  margin-top: 10px;
  background-color: #faf5ff;
}
.trendswipermobile-slide {

  background-position: center;
  background-size: cover;
  background: #fff;
  width: 300px;
  /* height: 370px; */
  align-items: center;
  border: 1px dotted #433832;
  /* padding: 10px; */
}

.trendswipermobile-slide .logomobile {
  /* width: 100%; */
  height: 108px;
}

.trendswipermobile-slide .imagemobile {
  width: 100%;
  height: 252px;
}

.trendmobswiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.trendmobswiper-slide .logo {
  width: 35%;
  height: 35%;
}
.trendmobswiper-slide .image {
  width: 100%;
  height: 70%;
}

.rswiper-slide img {
  display: block;
  width: 100%;
}

.swiper-button-next {
  color: #433832;
}
.swiper-button-prev {
  color: #433832;
}

.swipermobile .swiper-button-next {
  width: 5px;
  font-size: medium;
  height: auto;
  margin-bottom: 10px;
}
.swipermobile .swiper-button-prev {
  width: 5px;
  font-size: medium;
  height: auto;
  margin-bottom: 10px;
}
